import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    table: JSON.parse(sessionStorage.getItem("Table")) || {},
    token: sessionStorage.getItem("token") || "",
    hasLogin: sessionStorage.getItem("hasLogin") || false,
    userInfo: JSON.parse(sessionStorage.getItem("userInfo")) || {},
    alert: false,
  },
  mutations: {
    loginInfo(state, { token = '', hasLogin = false, userInfo = {} }) {
      state.hasLogin = hasLogin;
      state.token = token;
      state.userInfo = userInfo;
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("hasLogin", hasLogin);
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    logOut(state) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("hasLogin");
      sessionStorage.removeItem("userInfo");
      state.hasLogin = false;
      state.token = "";
      state.userInfo = {};
    },
    setTable(state, obj) {
      state.table = obj;
      sessionStorage.setItem("Table", JSON.stringify(obj));
    },
    showAlert(state, payload) {
      state.alert = payload;
    },
  },
  actions: {},
  modules: {},
  // plugins: [createPersistedState()],
});
