import zwitCore from "zw-core";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";
import axios from "axios";

export default {
  firstPage() {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/circle/nf/firstPage",
      {},
      false
    );
  },
  // 搜索学术圈
  fuzzySearchCircleTitle(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/circle/nf/fuzzySearchCircleTitle",
      data,
      false
    );
  },
  // 搜索讨论
  fuzzySearchCircleDiscuss(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/circle/nf/fuzzySearchCircleDiscuss",
      data,
      false
    );
  },
  // 学术圈详情
  circleDetail(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/userCircle/nf/circleDetail",
      data,
      false
    );
  },
  // 热门讨论
  hotDiscussList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/userCircle/nf/hotDiscussList",
      data,
      false
    );
  },
  // 圈子推荐
  circleIndexRecommendPage(data) {
    return zwitCore.HTTP.post(
      BASE_URL + "/zw-circle/userCircle/nf/circleIndexRecommendPage",
      data,
      false
    );
  },
  // 全部圈子
  circleList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/userCircle/nf/circleList",
      data,
      false
    );
  },
  // 圈子类型
  getCircleType() {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/circle/nf/getCircleType",
      {},
      false
    );
  },
  // 热门学者
  getHotScholarList() {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/userCircle/nf/getHotScholarList",
      {},
      false
    );
  },
  // 热门圈子
  getHotCircleList() {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/userCircle/nf/getHotCircleList",
      {},
      false
    );
  },
  // 学术圈详情成果
  achievementListByCircleIdOrDiscussId(data) {
    console.log(data);
    return zwitCore.HTTP.json(
      BASE_URL +
      "/zw-circle/userCircle/nf/achievementListByCircleIdOrDiscussIdAndUserId",
      data,
      false
    );
  },
  getFollowAllList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/userCircle/nf/getFollowAllList",
      data,
      false
    );
  },
  // 关注学者
  attentionList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/attention/nf/list",
      data,
      false
    );
  },
  // 添加讨论
  async addDiscuss(data) {
    // return zwitCore.HTTP.json(
    //   BASE_URL + "/zw-circle/userCircle/nf/addDiscuss",
    //   data,
    //   false
    // );
    return await axios({
      url: `${BASE_URL}/zw-circle/userCircle/nf/addDiscuss`,
      method: "post",
      data,
      headers: {
        rsa: "no",
      },
    });
  },
  // 修改讨论
  async updateDiscuss(data) {
    return await axios({
      url: `${BASE_URL}/zw-circle/userCircle/nf/updateDiscuss`,
      method: "post",
      data,
      headers: {
        rsa: "no",
      },
    });
  },
  // 讨论详情
  discussDetail(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/userCircle/nf/discussDetail",
      data,
      false
    );
  },
  // 讨论详情-回复列表
  discussReplyList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-circle/userCircle/nf/discussReplyList",
      data,
      false
    );
  },
  // 学术圈-讨论-增加回复
  async addReply(data) {
    console.log(data);
    return await axios({
      url: `${BASE_URL}/zw-circle/userCircle/nf/addReply`,
      method: "post",
      data,
      headers: {
        rsa: "no",
      },
    });
    // return zwitCore.HTTP.json(
    //   BASE_URL + "/zw-circle/userCircle/nf/addReply",
    //   data,
    //   false
    // );
  },
  // 根据用户ID得到当前用户的所有已发布文章(不包含资讯)
  getUserArticleList(data) {
    return zwitCore.HTTP.json(
      BASE_URL + "/zw-information/articleCircleInfo/getUserArticleList",
      data,
      false
    );
  },
  // 首页学术圈关注
  onFocus(data) {
    return zwitCore.HTTP.json(
      BASE_URL + "/zw-circle/userCircle/nf/attentionOrCancelCircle",
      data,
      false
    );
  },
  // 学术圈讨论问题关注
  onFocusPro(data) {
    console.log(data, 'data');
    return zwitCore.HTTP.json(
      BASE_URL + "/zw-circle/userCircle/nf/attentionOrCancelDiscuss",
      data,
      false
    );
  },

};
