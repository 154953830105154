import zwitCore from "zw-core";
import axios from "axios";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";

export default {
  articlePreview(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleUserCore/nf/articlePreview",
      data,
      false
    );
  },
  setPreview(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleUserCore/nf/setPreview",
      data,
      false
    );
  },
  // 学科
  getListByClassifyNumber(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-public/classification/getListByClassifyNumber",
      data,
      false
    );
  },
  // 回显
  getTRDetails(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleInfo/getTRDetails",
      data,
      false
    );
  },
  // 分类
  listByType(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-public/videoOrg/listByType",
      data,
      false
    );
  },
  async webSave(data) {
    return await axios({
      url: `${BASE_URL}/zw-information/articleInfo/websave`,
      method: "post",
      data,
      headers: {
        rsa: "no",
      },
    });
  },
  getTreeList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-knowledge/column/treeList",
      data,
      false
    );
  },
  // 资金类型列表
  fundTypeList() {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/fundType/fundTypeList", {});
  },
  // 基金列表
  page(data) {
    return zwitCore.HTTP.json(BASE_URL + "/zw-public/fund/page", data, false);
  },
  // 添加基金
  async addFund(data) {
    // return zwitCore.HTTP.json(
    //   BASE_URL + "/zw-information/articleFund/addFund",
    //   data,
    //   false
    // );
    return await axios({
      url: `${BASE_URL}/zw-information/articleFund/addFund`,
      method: "post",
      data,
      headers: {
        rsa: "no",
      },
    })
  },
  // 学科列表
  getSubjectList() {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/subject/getList", {});
  },
  // 行业分类一级列表
  getOneLists() {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/industry/getOneList", {});
  },
  // 行业分类二级列表
  getTwoLists(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-public/industry/getListByPid",
      data
    );
  },
  // 回显
  getEcho(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleInfo/getDetails",
      data,
      false
    );
  },
  // 栏目
  projectAndColumnListByType(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-knowledge/column/projectAndColumnListByType",
      data,
      false
    );
  },
  getIncludeList() {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/include/includeList");
  },
  // 查询文章发布作者
  findAuthorInfoByNameAndCompany(data) {
    return zwitCore.HTTP.post(
      BASE_URL + "/zw-user/searchUser/nf/findAuthorInfoByNameAndCompany",
      data,
      false
    );
  },
  // 报告类型
  getReportType() {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleBj/getReportType",
      {},
      false
    );
  },
  // 来源
  getSourceList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleBj/getSourceList",
      data,
      false
    );
  },
  // 分类法
  getClassificationOne() {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-public/classification/getOneList",
      {},
      false
    );
  },
  // 分类法
  getClassificationByPid(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-public/classification/getListByPid",
      data,
      false
    );
  },
  // 地域
  getProvince() {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-public/area/getProvince",
      {},
      false
    );
  },
  getCity(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-public/area/zw-public/area/getCity",
      data,
      false
    );
  },
};
