import formRouter from "./form.router";
import searchRouter from "./search.router";
import userRouter from "./user.router";
import circleRouter from "./circle.router";
import detailRouter from "./detail.router";
import previewRouter from "./preview.router";
import autherRouter from "./auther.router";
export default [
  {
    path: "/",
    name: "main",
    component: () => import("@/views/main"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home"),
    children: [
      ...searchRouter,
      ...circleRouter,
      ...detailRouter,
      ...previewRouter,
      {
        path: "/clause",
        name: "clause",
        component: () => import("@/components/pager/Clause.vue"),
      },
      {
        path: "/invalid",
        name: "invalid",
        component: () => import("@/views/form/preview/Invalid.vue"),
      }
    ],
  },
  {
    path: "/autherpicture",
    name: "autherpicture",
    component: () => import("@/views/auther/view/AutherPicture.vue"),
  },
  ...autherRouter,
  ...formRouter,
  ...userRouter,
];
