import store from "../store";
export default [
  {
    path: "/circle",
    name: "circle",
    component: () => import("@/views/circle"),
    redirect: "/recommendcircle",
    children: [
      {
        path: "/recommendcircle",
        name: "recommendcircle",
        component: () => import("@/views/circle/view/RecommendCircle.vue"),
        meta: {
          title: '学术圈-百家在线'
        }
      },
      {
        path: "/followcircle",
        name: "followcircle",
        component: () => import("@/views/circle/view/FollowCircle.vue"),
        beforeEnter: (to, from, next) => {
          // ...
          console.log(to, from, next);
          if (store.state.hasLogin) {
            next();
          } else {
            store.commit("showAlert", true);
            next(from.path);
          }
        },
      },
      {
        path: "/circlecircle",
        name: "circlecircle",
        component: () => import("@/views/circle/view/CircleCircle.vue"),
      },
    ],
  },
  {
    path: "/allcircle",
    name: "allcircle",
    component: () => import("@/views/circle/view/AllCircle.vue"),
  },
  {
    path: "/discussdetails",
    name: "discussdetails",
    component: () => import("@/views/circle/view/DiscussDetails.vue"),
    redirect: "/replydetail",
    children: [
      {
        path: "/replydetail",
        name: "replydetail",
        component: () => import("@/views/circle/components/ReplyDetail.vue"),
      },
      {
        path: "/replydetailachievements",
        name: "replydetailachievements",
        component: () =>
          import("@/views/circle/components/ReplyDetailAchievements.vue"),
      },
    ],
  },
  {
    path: "/circledetails",
    name: "circledetails",
    component: () => import("@/views/circle/view/CircleDetails.vue"),
    redirect: "/detaildiscuss",
    children: [
      {
        path: "/detaildiscuss",
        name: "detaildiscuss",
        component: () => import("@/views/circle/components/DetailDiscuss.vue"),
      },
      {
        path: "/detailachievements",
        name: "detailachievements",
        component: () =>
          import("@/views/circle/components/DetailAchievements.vue"),
      },
    ],
  },
];
