export default [
    {
        path: "/preview",
        name: "preview",
        component: () => import("@/views/form/preview"),
        children: [
            {
                path: "/paperpreview",
                name: "paperpreview",
                component: () => import("@/views/form/preview/view/PaperDetail.vue"),
            },
            {
                path: "/monographpreview",
                name: "monographpreview",
                component: () => import("@/views/form/preview/view/MonographDetail.vue"),
            },
            {
                path: "/patentpreview",
                name: "patentpreview",
                component: () => import("@/views/form/preview/view/PatentDetail.vue"),
            },
            {
                path: "/informationpreview",
                name: "informationpreview",
                component: () => import("@/views/form/preview/view/InformationDetail.vue"),
            },
            {
                path: "/conferencepaperspreview",
                name: "conferencepaperspreview",
                component: () => import("@/views/form/preview/view/ConferencePapers.vue"),
            },
            {
                path: "/conferencepptpreview",
                name: "conferencepptpreview",
                component: () => import("@/views/form/preview/view/ConferencePpt.vue"),
            },
            {
                path: "/conferenceotherpreview",
                name: "conferenceotherpreview",
                component: () => import("@/views/form/preview/view/ConferenceOther.vue"),
            },
            {
                path: "/standardpreview",
                name: "standardpreview",
                component: () => import("@/views/form/preview/view/StandardDetail.vue"),
            },
            {
                path: "/reportpreview",
                name: "reportpreview",
                component: () => import("@/views/form/preview/view/ReportDetail.vue"),
            },
        ]
    }
]
