export default [
  {
    path: "/autherdetail",
    name: "autherdetail",
    component: () => import("@/views/auther/index.vue"),
    redirect: "/autherplain",
    children: [
      {
        path: "/autherplain",
        name: "autherplain",
        component: () => import("@/views/auther/view/AutherPlain.vue"),
      },
      {
        path: "/autherachievements",
        name: "autherachievements",
        component: () => import("@/views/auther/view/AutherAchievements.vue"),
      },
      {
        path: "/autherblog",
        name: "autherblog",
        component: () => import("@/views/auther/view/AutherBlog.vue"),
      },
      {
        path: "/autherdynamic",
        name: "autherdynamic",
        component: () => import("@/views/auther/view/AutherDynamic.vue"),
      },
      {
        path: "/autheranalysis",
        name: "autheranalysis",
        component: () => import("@/views/auther/view/AutherAnalysis.vue"),
      },
    ],
  }
];
