export default [
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/search"),
    // redirect: "/auther",
    // children: [
    //   {
    //     path: "/auther",
    //     name: "auther",
    //     component: () => import("@/views/search/views/Auther.vue"),
    //   },
    //   {
    //     path: "/achievements",
    //     name: "achievements",
    //     component: () => import("@/views/search/views/Achievements.vue"),
    //   },
    //   {
    //     path: "/circle",
    //     name: "circle",
    //     component: () => import("@/views/search/views/Circle.vue"),
    //   },
    //   {
    //     path: "/discuss",
    //     name: "discuss",
    //     component: () => import("@/views/search/views/Discuss.vue"),
    //   },
    // ],
  },
  {
    path: "/moreauther",
    name: "moreauther",
    component: () => import("@/views/auther/view/MoreAuther.vue"),
  },
];
