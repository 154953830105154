import zwitCore from "zw-core";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";

export default {
  latestScholar(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/latestScholar",
      data,
      false
    );
  },
  getclause() {
    return zwitCore.HTTP.post(BASE_URL + "/zw-user/nf/termsOfUse", {}, false);
  },
};
