<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getPk, getTable } from "./apis/public";
export default {
  async created() {
    if (sessionStorage.getItem("webIp") == null || sessionStorage.getItem("webIp") == '') {
      await this.getWebIp();
    }
    this.getPublicPK();
    this.getPublicTable();
  },
  methods: {
    async getPublicPK() {
      let { pk, token } = await getPk();
      sessionStorage.setItem("trip", pk);
      if (token) {
        sessionStorage.setItem("token", token);
        this.getUserInfo()
      } else {
        this.$store.commit("logOut");
      }
    },
    async getUserInfo() {
      const res = await this.$apis.userServe.getUserInfo();
      if (res.resultCode === '200') {
        if (res.content.user.customerType === 1 || res.content.user.customerType === 2) {
          this.$store.commit("loginInfo", {
            token: res.data.token,
            hasLogin: true,
            userInfo: res.content,
          });
        } else {
          sessionStorage.removeItem("token");
        }
      }
    },
    async getPublicTable() {
      let res = await getTable();
      this.$store.commit("setTable", res);
    },
    async getWebIp() {
      try {
        let res = await this.$apis.loginServe.getUserIpConfig();
        if (res.status === 0) {
          sessionStorage.setItem("webIp", res.result.ip);
        } else {
          sessionStorage.setItem("webIp", '103.43.184.186');
        }
      } catch (error) {
        sessionStorage.setItem("webIp", '103.43.184.186');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  margin: 0 auto;
}

::v-deep .ivu-btn-primary[disabled] {
  color: #fff;
  background-color: #2d8cf0;
  border-color: #2d8cf0;

  &:hover {
    color: #fff;
    background-color: #2d8cf0;
    border-color: #2d8cf0;
  }
}
</style>
