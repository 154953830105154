export default [
  {
    path: "/user",
    redirect: "/user/literature",
    name: "user",
    component: () => import("@/views/user/index.vue"),
    children: [
      {
        path: "/user/attestation",
        name: "attestationUser",
        component: () => import("@/views/user/views/attestation"),
      },
      {
        path: "/user/literature",
        name: "literatureUser",
        component: () => import("@/views/user/views/literatureMrg"),
      },
      {
        path: "/user/scholarCertification",
        name: "scholarCertification",
        component: () => import("@/views/user/views/scholarCertification"),
      },
      {
        path: "/user/message",
        name: "messageUser",
        component: () => import("@/views/user/views/messageMrg"),
      },
      {
        path: "/user/discussion",
        name: "discussionUser",
        component: () => import("@/views/user/views/discussionMrg"),
      },
      {
        path: "/user/collect",
        name: "collectUser",
        component: () => import("@/views/user/views/collectMrg"),
      },
      {
        path: "/user/attention",
        name: "attentionUser",
        component: () => import("@/views/user/views/attentionData"),
        redirect: "/userauther",
        children: [{
          path: "/userauther",
          name: "userauther",
          component: () => import("@/views/user/views/attentionData/components/students.vue"),
        }, {
          path: "/usercircle",
          name: "usercircle",
          component: () => import("@/views/user/views/attentionData/components/academicCircle.vue"),
        }, {
          path: "/userdiscuss",
          name: "userdiscuss",
          component: () => import("@/views/user/views/attentionData/components/userdiscuss.vue"),
        },]
      },
      {
        path: "/user/fans",
        name: "fansUser",
        component: () => import("@/views/user/views/fansData"),
      },
      {
        path: "/user/system",
        name: "systemUser",
        component: () => import("@/views/user/views/systemMsg"),
      },
      {
        path: "/user/letter",
        name: "letterUser",
        component: () => import("@/views/user/views/letterMsg"),
      },
      {
        path: "/user/user",
        name: "userUser",
        component: () => import("@/views/user/views/userSet"),
      },
      {
        path: "/user/scholar",
        name: "scholarUser",
        component: () => import("@/views/user/views/scholar"),
        redirect: "/usermessage",
        children: [
          {
            path: "/usermessage",
            name: "usermessage",
            component: () => import("@/views/user/views/scholar/components/userInfo.vue"),
          },
          {
            path: "/userpersonal",
            name: "userpersonal",
            component: () => import("@/views/user/views/scholar/components/personage.vue"),
          },
          {
            path: "/useraward",
            name: "useraward",
            component: () => import("@/views/user/views/scholar/components/scholarship.vue"),
          },
          {
            path: "/userpicture",
            name: "userpicture",
            component: () => import("@/views/user/views/scholar/components/UserPicture.vue"),
          },
        ]
      },
      {
        path: "/user/privacy",
        name: "privacyUser",
        component: () => import("@/views/user/views/privacySet"),
      },
      {
        path: "/user/honor",
        name: "honorUser",
        component: () => import("@/views/user/views/honorEquity"),
      },
      {
        path: "/user/claim",
        name: "claimUser",
        component: () => import("@/views/user/views/claimEquity"),
      },
      {
        path: "/user/history",
        name: "history",
        component: () => import("@/views/user/views/history"),
        redirect: "/historybrowse",
        children: [
          {
            path: "/historybrowse",
            name: "historybrowse",
            component: () => import("@/views/user/views/history/components/Look.vue"),
          },
          {
            path: "/historydown",
            name: "historydown",
            component: () => import("@/views/user/views/history/components/Down.vue"),
          },
        ]
      },
    ],
  },
];
