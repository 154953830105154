import Vue from "vue";

// const isDev = process.env.NODE_ENV === "development";
let global = {
  // 测试服务
  // url1: "https://wxtest.cnknowledge.com/api", // 基础路径/下载/上传地址
  // // url2: isDev ? "http://192.168.1.14:9090/knowledge-web" : "http://39.105.30.106:9090/knowledge-web",
  // url3: "http://newbjzx.cnknowledge.com", // 二维码地址
  // imgUrl: "http://fdfs.cnknowledge.com/", // 图片基地址
  // imgUrl1: "http://newbjzx.cnknowledge.com:5052/api/zw-user/", // 荣誉ICON图标地址
  // imgUrl2: "http://47.93.50.49:5052/", // 数据库固定图片地址
  // testUrl: 'http://test.cnknowledge.com/admin/', // 跳转中文知识网地址

  //正式服务
  url1: "https://zwi.cnknowledge.com/api", // 基础路径/下载/上传地址
  // url2: "http://39.105.30.106:9090/knowledge-web",
  url3: "https://www.baijiazaixian.com", // 二维码地址
  imgUrl: "https://kpp.cnknowledge.com/", // 图片基地址
  imgUrl1: "https://zwi.cnknowledge.com/api/zw-user/", // 荣誉ICON图标地址
  imgUrl2: "https://zwi.cnknowledge.com/", // 数据库固定图片地址
  testUrl: "https://www.cnknowledge.com/admin/", // 跳转中文知识网地址
};
export default {
  install() {
    Vue.prototype.$global = global;
  },
  global,
};
