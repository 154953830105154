import zwitCore from "zw-core";
import global from '../utils/global.js';
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";
const download = `${global.global.url1}/zw-public/web/common/downloadArticle2`;
const downloadNoToken = `${global.global.url1}/zw-public/web/common/downloadArticle3`;
const readUrl = `${global.global.url1}/zw-public/web/common/readPDFPicFile2`;
const readUrlNoToken = `${global.global.url1}/zw-public/web/common/readPDFPicFile3`;
export default {
  download,
  readUrl,
  downloadNoToken,
  readUrlNoToken,
  isCollect(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleCollect/isCollect",
      data
    );
  },
  CollectItem(data) {
    return zwitCore.HTTP.json(
      BASE_URL + "/zw-information/articleCollect/addOrDelCollect",
      data
    );
  },
  downPdf(data) {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/web/common/download", data);
  },
  // 搜索推荐列表
  getSearchList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleInfo/queryESListHasCollerBj",
      data,
      false
    );
  },
  getIncludeList() {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/include/includeList");
  },
  getYearList(data) {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/year/infoPage", data);
  },
  getArticleCount(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/getArticleCount",
      data,
      false
    );
  },
  getTypeCount(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/getTypeCount",
      data,
      false
    );
  },
  getYearCount(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/getYearCount",
      data,
      false
    );
  },
  getDataStyleCount(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/getDataStyleCount",
      data,
      false
    );
  },
  searchScholarList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/searchScholar",
      data,
      false
    );
  },
};
