import zwitCore from "zw-core";
import axios from "axios";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";

export default {
    // 下架
    closeFile(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleInfo/closeFile ",
            data,
            false
        );
    },
    // 个人中心退领接口 articleId，articleType 
    withdrawalArticle(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-information/articleUserCore/nf/withdrawalArticle",
            data,
            false
        );
    },
    // 用户认领学者
    async claimScholar(data) {
        return await axios({
            url: `${BASE_URL}/zw-user/scholar/nf/claimScholar`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    // 荣誉认证去认证
    async honourAuth(data) {
        return await axios({
            url: `${BASE_URL}/zw-user/honour/nf/honourAuth`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    // 学者推荐-暂时不再推荐某学者
    notRecommend(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/nf/notRecommend",
            data,
            false
        );
    },
    // 为当前登录学者推荐同名待认领学者
    recommendScholar(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/nf/recommendScholar",
            data,
            false
        );
    },
    // 获取荣誉列表
    getUserHonourList() {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/honour/nf/getUserHonourList",
            {},
            false
        );
    },
    // 学者删除一条获奖情况
    delPrize(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/nf/delPrize",
            data,
            false
        );
    },
    // 学者删除一条图集
    delGallery(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/nf/delGallery",
            data,
            false
        );
    },
    prizeInfo(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/nf/prizeInfo",
            data,
            false
        );
    },
    // 学者提交获奖情况审核
    async commitPrize(data) {
        // return zwitCore.HTTP.json(
        //     BASE_URL + "/zw-user/scholar/nf/commitPrize",
        //     data,
        //     false
        // );
        return await axios({
            url: `${BASE_URL}/zw-user/scholar/nf/commitPrize`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    // 学者修改一条获奖情况
    async modifyPrize(data) {
        // return zwitCore.HTTP.json(
        //     BASE_URL + "/zw-user/scholar/nf/modifyPrize",
        //     data,
        //     false
        // );
        return await axios({
            url: `${BASE_URL}/zw-user/scholar/nf/modifyPrize`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    // 学者提交图集审核
    async commitGallery(data) {
        // return zwitCore.HTTP.json(
        //     BASE_URL + "/zw-user/scholar/nf/commitGallery",
        //     data,
        //     false
        // );
        return await axios({
            url: `${BASE_URL}/zw-user/scholar/nf/addGallery`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    // 学者修改一条图集
    async modifyGallery(data) {
        // return zwitCore.HTTP.json(
        //     BASE_URL + "/zw-user/scholar/nf/modifyGallery",
        //     data,
        //     false
        // );
        return await axios({
            url: `${BASE_URL}/zw-user/scholar/nf/modifyGallery`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    // 学者中心- 学术圈关注列表
    getFollowCircleList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-circle/userCircle/nf/getFollowCircleList",
            data,
            false
        );
    },
    // 学者中心-讨论列表
    getFollowDiscussList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-circle/userCircle/nf/getFollowDiscussList",
            data,
            false
        );
    },
    // 学者获取图集信息
    gallery(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/scholar/nf/gallery",
            data,
            false
        );
    },
    getUserMessageMrgList(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getInformationList",
            data,
            false
        );
    },
    downId(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleInfo/closeFile",
            data,
            false
        );
    },
    delId(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleInfo/deleteArticle",
            data,
            false
        );
    },
    getUserContentMrgList(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getThesisList",
            data,
            false
        );
    },
    science() {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-information/articleUserCore/getUserChannelList",
            {},
            false
        );
    },
    asyncFamilies(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/syncArticle",
            data,
            false
        );
    },
    apply(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-information/articleApplyColumn/getColumnList",
            data,
            false
        );
    },
    setStatus(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleApplyColumn/applyColumn",
            data,
            false
        );
    },
    browseList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-admin/history/browsebj",
            data,
            false
        );
    },
    dwonList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-admin/history/downloadbj",
            data,
            false
        );
    },
    async webSave(data) {
        return await axios({
            url: `${BASE_URL}/zw-information/articleInfo/websave`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    // 征稿函提交修改
    async addLetter(data) {
        console.log(data);
        // return zwitCore.HTTP.json(
        //   BASE_URL + "/zw-knowledge/solicitation/addLetter",
        //   data,
        //   false
        // );
        return await axios({
            url: `${BASE_URL}/zw-knowledge/solicitation/addLetter`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    getLetter(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-knowledge/solicitation/detail",
            data,
            false
        );
    },
    setSonPwd(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/reset/nf/subUserUpdatePwd",
            data,
            false
        );
    },
    getTreeList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-knowledge/column/treeList",
            data,
            false
        );
    },
    paperList(data) {
        //期刊论文
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getUserThesis0List",
            data,
            false
        );
    },
    PatentsList(data) {
        //专利
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getUserPatentList",
            data,
            false
        );
    },
    concentrationList(data) {
        //专注
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getUserMonographList",
            data,
            false
        );
    },
    ResearchList(data) {
        //科技报告
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getUserTechnologyReportList",
            data,
            false
        );
    },
    achievementList(data) {
        //会议成果
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getUserThesis1List",
            data,
            false
        );
    },
    standardList(data) {
        //标准
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getUserStandardList",
            data,
            false
        );
    },
    restsList(data) {
        //其他
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getUserThesis4List",
            data,
            false
        );
    },
    userDetails() {
        return zwitCore.HTTP.json(BASE_URL + "/zw-user/getUserDetail", {}, false);
    },
    systemMsg(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/msg/nf/sysMessageList",
            data,
            false
        );
    },
    // 收藏管理 // 咨询列表
    getUserCollectInformationList(data) {
        return zwitCore.HTTP.get(
            BASE_URL +
            "/zw-information/articleUserCore/nf/getUserCollectInformationList",
            data,
            false
        );
    },
    // 收藏管理 文献列表
    getUserCollectThesisList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-information/articleUserCore/nf/getUserCollectThesisList",
            data,
            false
        );
    },
    // 查询文章发布作者
    findAuthorInfoByNameAndCompany(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/searchUser/nf/findAuthorInfoByNameAndCompany",
            data,
            false
        );
    },
    setPwd(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/reset/userResetPwd",
            data,
            false
        );
    },
    setPhone(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/reset/userResetAccountPwd",
            data,
            false
        );
    },
    getUserGroupList(data) {
        return zwitCore.HTTP.post(BASE_URL + "/zw-user/role/roleList", data, false);
    },
    getUserList(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/subUser/subUserList",
            data,
            false
        );
    },
    addUserGroup(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/subUser/linkUser2Role",
            data,
            false
        );
    },
    removePwd(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/reset/subUserResetPwd",
            data,
            false
        );
    },
    deleteUser(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/subUser/delSubUser",
            data,
            false
        );
    },
    createUserGroup(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/role/createRole",
            data,
            false
        );
    },
    createUser(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/subUser/create",
            data,
            false
        );
    },
    deleteUserGroup(data) {
        return zwitCore.HTTP.post(BASE_URL + "/zw-user/role/delRole", data, false);
    },
    getJurisdiction(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/subUser/getRationPermissionList",
            data,
            false
        );
    },
    setJurisdiction(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/subUser/addPermissionOfRole",
            data,
            false
        );
    },
    userAndUserGroup(data) {
        return zwitCore.HTTP.post(
            BASE_URL + "/zw-user/subUser/linkUser2Role",
            data,
            false
        );
    },
    // 资金类型列表
    fundTypeList() {
        return zwitCore.HTTP.get(BASE_URL + "/zw-public/fundType/fundTypeList", {});
    },
    // 基金列表
    page(data) {
        return zwitCore.HTTP.json(BASE_URL + "/zw-public/fund/page", data, false);
    },
    // 添加基金
    addFund(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleFund/addFund",
            data,
            false
        );
    },
    // 学科列表
    getSubjectList() {
        return zwitCore.HTTP.get(BASE_URL + "/zw-public/subject/getList", {});
    },
    // 行业分类一级列表
    getOneLists() {
        return zwitCore.HTTP.get(BASE_URL + "/zw-public/industry/getOneList", {});
    },
    // 行业分类二级列表
    getTwoLists(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-public/industry/getListByPid",
            data
        );
    },
    getOrDetails() {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/org/nf/selfDetail",
            {},
            false
        );
    },
    getMaDetails() {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/periodical/selfDetail",
            {},
            false
        );
    },
    getexperience() {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/nf/experience",
            {},
            false
        );
    },

    commitEduExperience(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/scholar/commitEduExperience",
            data,
            false
        );
    },

    commitWorkExperience(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/scholar/commitWorkExperience",
            data,
            false
        );
    },
    commitTitleExperience(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/scholar/commitTitleExperience",
            data,
            false
        );
    },
    commitMoonLightingExperience(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/scholar/commitMoonLightingExperience",
            data,
            false
        );
    },
    delEduExperience(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/delEduExperience",
            data,
            false
        );
    },
    delWorkExperience(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/delWorkExperience",
            data,
            false
        );
    },
    delTitleExperience(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/delTitleExperience",
            data,
            false
        );
    },
    elMoonLightingExperience(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/delMoonLightingExperience",
            data,
            false
        );
    },
    getTitleList() {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-public/title/getTitleList",
            {},
            false
        );
    },
    levelThree(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-public/title/getThreeList",
            data,
            false
        );
    },
    getOneList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-public/researchField/getOneList",
            data,
            false
        );
    },
    getTwoList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-public/researchField/getTwoList",
            data,
            false
        );
    },
    getThreeList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-public/researchField/getThreeList",
            data,
            false
        );
    },
    async setUserInfo(data) {
        return await axios({
            url: `${BASE_URL}/zw-user/scholar/modifyBaseInfo`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
        // return zwitCore.HTTP.json(
        //   BASE_URL + "/zw-user/scholar/modifyBaseInfo",
        //   data,
        //   false
        // );
    },
    async setMaInfo(data) {
        console.log(data);
        return await axios({
            url: `${BASE_URL}/zw-user/periodical/update`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    async subPeriodical(data) {
        console.log(data);
        return await axios({
            url: `${BASE_URL}/zw-user/periodical/auth`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    async subscholar(data) {
        console.log(data);
        return await axios({
            url: `${BASE_URL}/zw-user/scholar/authScholar`,
            method: "post",
            data,
            headers: {
                rsa: "no",
            },
        });
    },
    // setMaInfo(data){
    //   console.log(data,'data');
    //   return zwitCore.HTTP.json(
    //   BASE_URL + "/zw-user/periodical/update",
    //   data,
    //   false
    // );
    // },
    setImg(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/org/changeCompany",
            data,
            false
        );
    },
    setName(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/org/updateOrgInfo",
            data,
            false
        );
    },

    modifyEduExperience(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/scholar/modifyEduExperience",
            data,
            false
        );
    },
    modifyMoonLightingExp(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/scholar/modifyMoonLightingExp",
            data,
            false
        );
    },
    modifyTitleExperience(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/scholar/modifyTitleExperience",
            data,
            false
        );
    },
    modifyWorkExperience(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-user/scholar/modifyWorkExperience",
            data,
            false
        );
    },
    getwenzhai(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getUserThesis3List",
            data,
            false
        );
    },
    getHYBG(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-information/articleUserCore/getUserReportList",
            data,
            false
        );
    },
    // 回显
    getEcho(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-information/articleInfo/getDetails",
            data,
            false
        );
    },
    userEditType() {
        return zwitCore.HTTP.get(BASE_URL + "/zw-user/scholar/baseInfo", {}, false);
    },
    userEditTypes() {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/nf/authStatus",
            {},
            false
        );
    },
    msgDetails(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-user/scholar/msg/nf/sysMessageDetail",
            data,
            false
        );
    },
    proList() {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-public/area/getProvince",
            {},
            false
        );
    },
    cityList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-public/area/zw-public/area/getCity",
            data,
            false
        );
    },
    zghList(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-knowledge/solicitation/myPage",
            data,
            false
        );
    },
    zghxz(data) {
        return zwitCore.HTTP.patch(
            BASE_URL + "/zw-knowledge/solicitation/letterDown",
            data,
            false
        );
    },
    zghdel(data) {
        return zwitCore.HTTP.delete(
            BASE_URL + "/zw-knowledge/solicitation/delLetter",
            data,
            false
        );
    },
    // 分类
    listByType(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-public/videoOrg/listByType",
            data,
            false
        );
    },
    // 栏目
    projectAndColumnListByType(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-knowledge/column/projectAndColumnListByType",
            data,
            false
        );
    },
    getCollectionList(data) {
        return zwitCore.HTTP.get(
            BASE_URL + "/zw-information/articleVideo/collectionList",
            data,
            false
        );
    },
    getUserInfo() {
        return zwitCore.HTTP.get(BASE_URL + "/zw-user/nf/getloginInfo", {}, false);
    },
    //编辑个人履历
    async addOrEditExperience(data) {
        console.log('个人履历参数:',data)
        return await axios({
        url: `${BASE_URL}/zw-user/scholar/nf/addOrEditExperience`,
        method: "post",
        data,
        headers: {
            rsa: "no",
        },
        });
    },
  // 获取获奖情况
  getPrizeInfo() {
    console.log('获取获奖情况')
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/prizeInfo",
      {},
      false
    );
  },
  //编辑获奖情况
  async addOrEditPrize(data) {
    console.log('获奖情况参数:',data)
    return await axios({
      url: `${BASE_URL}/zw-user/scholar/nf/addOrEditPrize`,
      method: "post",
      data,
      headers: {
        rsa: "no",
      },
    });
  },
    educationOptions(data) {
        return zwitCore.HTTP.json(
            BASE_URL + "/zw-public/code/getEduDictionary",
            data,
            false
        );
    },
};
