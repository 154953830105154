export default [
  {
    path: "/form",
    name: "form",
    component: (resolve) => require(["@/views/form/index.vue"], resolve),
    redirect: "informationForm",
    children: [
      {
        path: "/paperForm",
        name: "paperForm",
        component: (resolve) =>
          require(["@/views/form/view/PaperForm.vue"], resolve),
      },
      {
        path: "/informationForm",
        name: "informationForm",
        component: (resolve) =>
          require(["@/views/form/view/InformationForm.vue"], resolve),
      },
      // {
      //   path: "/meetingForm",
      //   name: "meetingForm",
      //   component: (resolve) =>
      //     require(["@/views/form/view/MeetingForm.vue"], resolve),
      // },
      {
        path: "/monographForm",
        name: "monographForm",
        component: (resolve) =>
          require(["@/views/form/view/MonographForm.vue"], resolve),
      },
      {
        path: "/patentform",
        name: "patentform",
        component: (resolve) =>
          require(["@/views/form/view/PatentForm.vue"], resolve),
      },
      {
        path: "/otherform",
        name: "otherform",
        component: (resolve) =>
          require(["@/views/form/view/OtherForm.vue"], resolve),
      },
      {
        path: "/reportform",
        name: "reportform",
        component: (resolve) =>
          require(["@/views/form/view/ReportForm.vue"], resolve),
      },
      {
        path: "/standardform",
        name: "standardform",
        component: (resolve) =>
          require(["@/views/form/view/StandardForm.vue"], resolve),
      },
    ],
  },
];
