import Vue from "vue";
import mainServe from "../main.serve";
import circleServe from "../circle.serve";
import detailServe from "../detail.serve";
import searchServe from "../search.serve";
import formServe from "../form.serve";
import autherServe from "../auther.serve";
import userServe from "../user.serve";
import loginServe from "../login.serve";
const apis = {
  circleServe,
  detailServe,
  searchServe,
  formServe,
  mainServe,
  autherServe,
  userServe,
  loginServe
};

export default {
  install() {
    Vue.prototype.$apis = apis;
  },
};
