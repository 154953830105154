import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//请求拦截
import "@/utils/response.js";
// 引入公共样式
import "./assets/css/global.css";
// 组件库
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
Vue.use(ViewUI);
//注册全局通用属性,方法
import global from "@/utils/global.js";
Vue.use(global);
// 接口
import apis from "./apis/config";
Vue.use(apis);
// 事件总线
import bus from "./eventBus";
Vue.prototype.$bus = bus;
//懒加载
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);
// 注册按钮全局节流指令
import throttleClick from "@/utils/throttle.js"
Vue.use(throttleClick)
Vue.filter("dayFormate", function (time) {
  //这是处理掉时间的
  let year = new Date(time).getFullYear();
  let month = new Date(time).getMonth() + 1;
  month < 10 ? (month = `0${month}`) : month;
  let day = new Date(time).getDate();
  day < 10 ? (day = `0${day}`) : day;
  // console.log(day);
  // console.log(new Date(time).getTime());
  if (isNaN(year)) {
    return "";
  }
  return `${year}-${month}-${day}`;
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
