import zwitCore from "zw-core";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";
export default {
  login(data) {
    // console.log(data);
    return zwitCore.HTTP.json(BASE_URL + "/zw-user/login", data, false);
  },
  getCode(data) {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/sms/send", data, false);
  },
  reg(data) {
    return zwitCore.HTTP.json(BASE_URL + "/zw-user/register/user", data, false);
  },
  logOut() {
    return zwitCore.HTTP.json(BASE_URL + "/zw-user/logout");
  },
  sonLogin(data) {
    return zwitCore.HTTP.json(
      BASE_URL + "/zw-user/subUser/nf/login",
      data,
      false
    );
  },
  getclause() {
    return zwitCore.HTTP.post(BASE_URL + "/zw-user/nf/termsOfUse", {}, false);
  },
  repwd(data) {
    return zwitCore.HTTP.post(
      BASE_URL + "/zw-user/reset/nf/userRetrievePwd",
      data,
      false
    );
  },
  getUserInfo() {
    return zwitCore.HTTP.get(BASE_URL + "/zw-user/nf/getloginInfo", {}, false);
  },

  // 获取公网ip地址接口(腾讯三方 https://lbs.qq.com/service/webService/webServiceGuide/webServiceIp)
  getUserIpConfig() {
    return new Promise((resolve) => {
      jsonp(
        `https://apis.map.qq.com/ws/location/v1/ip?key=YFTBZ-LCIWT-OOGXF-VWUF4-PXHK6-T6BVO`,
        {
          output: "jsonp",
        }
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
};
