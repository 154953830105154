import zwitCore from "zw-core";
import axios from "axios";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";

const download =
  "https://zwi.cnknowledge.com/api/zw-public/web/common/downloadArticle";
export default {
  download,
  isCollect(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleCollect/isCollect",
      data
    );
  },
  CollectItem(data) {
    return zwitCore.HTTP.json(
      BASE_URL + "/zw-information/articleCollect/addOrDelCollect",
      data
    );
  },
  downPdf(data) {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/web/common/download", data);
  },
  getRecomendList(params) {
    return zwitCore.HTTP.post(BASE_URL + "/zw-user/org/nf/detail", params);
  },
  getTable() {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/dictionaries/info");
  },
  getDetails(params) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleInfo/getDetails",
      params,
      false
    );
  },
  // getWenXian(params) {
  //   console.log('请求参数:',params)
  //   return zwitCore.HTTP.post(
  //     BASE_URL + "/zw-information/articleBj/getPostBeSimilarBjList",
  //     params,
  //     headers: {
  //       rsa: "no",
  //     },
  //     // false
  //   );
  // },
  async getWenXian(params) {
    console.log('参数:',params)
    return await axios({
      url: `${BASE_URL}/zw-information/articleBj/getPostBeSimilarBjList`,
      method: "post",
      params,
      headers: {
        rsa: "no",
      },
    });
  },
  getAuthor(data) {
    console.log(data, "data");
    return zwitCore.HTTP.post(
      BASE_URL + "/zw-user/searchUser/nf/findArticleReleaseNameById",
      data,
      false
    );
  },
  async getAuthorList(data) {
    return await axios({
      url: `${BASE_URL}/zw-user/searchUser/nf/getAuthorByUserId`,
      method: "post",
      data,
      headers: {
        rsa: "no",
      },
    });
  },
  periodical(data) {
    return zwitCore.HTTP.post(
      BASE_URL + "/zw-user/periodical/nf/detail",
      data,
      false
    );
  },
  periodiID(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleInfo/getPeriodicalCount",
      data,
      false
    );
  },
  PublisherInfo(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleInfo/getCreateUserCount",
      data,
      false
    );
  },
  solicitation(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-knowledge/solicitation/detail",
      data,
      false
    );
  },
  videoDetails(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleVideo/detail",
      data,
      false
    );
  },
  newVideos(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleVideo/newestList",
      data,
      false
    );
  },
  hasFile(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-public/web/common/hasFile",
      data,
      false
    );
  },
};
