export default [
  {
    path: "/detail",
    name: "detail",
    component: () => import("@/views/details"),
    children: [
      {
        path: "/paperdetail",
        name: "paperdetail",
        component: () => import("@/views/details/view/PaperDetail.vue"),
      },
      {
        path: "/monographdetail",
        name: "monographdetail",
        component: () => import("@/views/details/view/MonographDetail.vue"),
      },
      {
        path: "/patentdetail",
        name: "patentdetail",
        component: () => import("@/views/details/view/PatentDetail.vue"),
      },
      {
        path: "/informationdetail",
        name: "informationdetail",
        component: () => import("@/views/details/view/InformationDetail.vue"),
      },
      {
        path: "/conferencepapers",
        name: "conferencepapers",
        component: () => import("@/views/details/view/ConferencePapers.vue"),
      },
      {
        path: "/conferenceppt",
        name: "conferenceppt",
        component: () => import("@/views/details/view/ConferencePpt.vue"),
      },
      {
        path: "/conferenceother",
        name: "conferenceother",
        component: () => import("@/views/details/view/ConferenceOther.vue"),
      },
      {
        path: "/standarddetail",
        name: "standarddetail",
        component: () => import("@/views/details/view/StandardDetail.vue"),
      },
      {
        path: "/reportdetail",
        name: "reportdetail",
        component: () => import("@/views/details/view/ReportDetail.vue"),
      },
    ],
  },
];
