import axios from "axios";
import global from "./global";
import Store from "../store";
import { Message } from "view-design";
import SparkMD5 from "spark-md5";
/* 请求拦截器 */
axios.interceptors.request.use(
  (config) => {
    if (process.env.NODE_ENV === "development") {
      config.headers['zw-test'] = "zwjt801"
    } else {
      config.url = global.global.url1 + config.url;
    }
    config.withCredentials = true
    config.headers['Cache-Control'] = 'no-cache'
    let token = sessionStorage.getItem("token");
    if (token) {
      config.headers.common["token"] = token
      let ip = sessionStorage.getItem('webIp')
      let timestamp = new Date().getTime()
      let nonce = ip + '-' + timestamp

      let sign = token + nonce
      let spark = new SparkMD5()
      spark.append(sign);
      let signMd5 = spark.end();
      config.headers['zw-nonce'] = nonce
      config.headers['zw-sign'] = signMd5
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    if (response.data.resultCode === "-1") {
      Message.error("服务器开小差了，请稍后重试");
    } else if (response.data.resultCode === "1009") {
      if (response.data.resultDesc) {
        if (response.data.resultDesc === 'token过期，请重新登录！' || response.data.resultDesc === 'token过期!') {
          Message.error('登录失效,请重新登录');
          Store.commit('logOut')
        } else {
          Message.error(response.data.resultDesc);
        }
      } else {
        Message.error("请重新登录");
      }
    }
    //   else if (!response.data.resultCode==='200'){
    //   Message.error(response.data.resultDesc)
    // }
    return response;
  },
  function (error) {
    // 对响应错误做点什么x
    // console.log(error, "error");
    // Message.error("服务器开小差了，请稍后重试");
    // console.log(error, "error");
    // Message.error("服务器开小差了，请稍后重试");
    // Store.commit("logOut");
    // Router.push("/");
    return Promise.reject(error);
  }
);
