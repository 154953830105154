import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // document.documentElement.scrollTop = 0; // 滚动条回到顶部
  // document.title = "中文知识网";
  
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '百家在线'
  }

  if (to.name.indexOf("User") !== -1 && sessionStorage.getItem("hasLogin") !== "true") {
    next("/");
    return
  }
  next();

  // if (to.name.indexOf("User") !== -1) {
  //   if (sessionStorage.getItem("hasLogin") === "true") {
  //     next();
  //   } else {
  //     next("/");
  //   }
  // } else {
  //   next();
  // }
});
export default router;
