import zwitCore from "zw-core";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";
//公钥
export function getPk() {
  return zwitCore.HTTP.get(BASE_URL + "/zw-user/getPk");
}
//获取平台id
export function getTable() {
  return zwitCore.HTTP.get(BASE_URL + "/zw-public/dictionaries/info");
}

export function getOneList() {
  return zwitCore.HTTP.get(BASE_URL + "/zw-public/researchField/getOneList", {});
}
export function getThreeList(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-public/researchField/getThreeList",
    data,
    false
  );
}
export function getTwoList(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-public/researchField/getTwoList",
    data,
    false
  );
}
export function levelThree(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-public/title/getThreeList",
    data,
    false
  );
}
export function levelOne() {
  return zwitCore.HTTP.get(BASE_URL + "/zw-public/title/getOneList", {}, false);
}
export function levelTwo(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-public/title/getTwoList",
    data,
    false
  );
}
//获取验证码
export function getCode(data) {
  return zwitCore.HTTP.get(BASE_URL + "/zw-public/sms/send", data, false);
}
